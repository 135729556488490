<template>
  <b-card class="col-10 offset-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 p-0">
    <div class="form-inputs--lg">
      <b-form @submit.prevent="submitLogin" class="mb-3">
        <b-row>
          <b-col md="12">
            <b-form-group>
              <b-form-input
                id="email"
                :state="validateState($v.formLogin.email)"
                required
                type="email"
                v-model="$v.formLogin.email.$model"
                placeholder="Email"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group>
              <b-form-input
                id="password"
                :state="validateState($v.formLogin.password)"
                required
                type="password"
                v-model="$v.formLogin.password.$model"
                placeholder="Password"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center pt-2">
            <ButtonLoader
              content="ACCEDI"
              buttonStyle="success"
              buttonClass="btn-block btn-lg"
              v-bind:loading="submitted"
            />
          </b-col>
        </b-row>
      </b-form>
      <p class="text-center">Non possiedi un account?
        <b-link to="registrati">Effettua la registrazione</b-link>
      </p>
      <p class="m-0 text-center">Hai dimenticato la password?
        <b-link to="password-recovery">Clicca qui</b-link>
      </p>
    </div>
  </b-card>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import minLength from 'vuelidate/lib/validators/minLength';
import email from 'vuelidate/lib/validators/email';
import { extractErrorMessage, validateFormFieldState } from '@/utils/validators';

const ButtonLoader = () => import('@/components/ButtonLoader.vue');

export default {
  name: 'FormLogin',
  components: { ButtonLoader },
  props: {
    noRedirect: Boolean,
  },
  data() {
    return {
      successful: false,
      submitted: false,
      formLogin: {
        email: '',
        password: '',
      },
    };
  },
  validations: {
    formLogin: {
      email: {
        email,
        required,
      },
      password: {
        required,
        minLength: minLength(8),
      },
    },
  },
  methods: {
    validateState: validateFormFieldState,
    submitLogin() {
      const login = {
        username: this.formLogin.email,
        password: this.formLogin.password,
      };
      this.submitted = true;
      this.$store.dispatch('auth/login', login).then((response) => {
        this.message = response.message;
        this.successful = true;
        if (!this.noRedirect) {
          const path = this.$store.getters['auth/getPath'];
          this.$store.dispatch('auth/cleanPath');
          this.$router.replace(path);
        }
        this.submitted = false;
      },
      (error) => {
        this.message = extractErrorMessage(error);
        this.successful = false;
        this.submitted = false;
      });
    },
  },
};
</script>
